const BlogContent = [
    {
        images: '01',
        title: ' Mudar às Vezes e Necessário',
        category: 'Development',
        url:'Mudar_às_Vezes_e_Necessário'
    },
    {
        images: '02',
        title: '50 Mensagens para mandar pra quem voce ama',
        category: 'Management',
        url:'Mudar_às_Vezes_e_Necessário'
    },

    {
        images: '03',
        title: 'Deus por favor me de forcas',
        category: 'Design',
        url:'Mudar_às_Vezes_e_Necessário'
    },
    {
        images: '04',
        title: ' Verdades sobre seguir seus sonhos',
        category: 'Development'
    },
    {
        images: '05',
        title: '6 coisas para manter seu relacionamento saudavel',
        category: 'Management'
    },
    {
        images: '06',
        title: 'Quando voce conhece a pessoa certa',
        category: 'Design'
    },
    {
        images: '07',
        title: ' 10 sinais de que voce encontrou um homem e nao um garoto',
        category: 'Development'
    },
    {
        images: '08',
        title: 'Se afaste desse amor que nao te merece',
        category: 'Management'
    },

    {
        images: '09',
        title: 'Meu amor tenho muita sorte de ter encontrado voce',
        category: 'Design'
    },
    {
        images: '10',
        title: ' 5 pequenos momentos para apreciar quando voce esta apaixonado',
        category: 'Development'
    },
    {
        images: '11',
        title: '10 coisas que aprendi em 30 dias de isolamento',
        category: 'Management'
    },
    {
        images: '12',
        title: 'Ao meu melhor amigo obrigado por ser minhaa alma gemea',
        category: 'Design'
    },
]

export default BlogContent;